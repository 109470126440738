<script>
export default {
  name: "SponsorBox",
}
</script>

<template>
    <div class="p-2 mb-2 bg-light rounded-3">
      <div id="sponsormessage">
        <div>Menossa mukana!</div><br/>
        <div><a href="http://www.solita.fi" target="_new"><img src="@/assets/img/SOLITA_BLACK_HOR_RGB.png" id="solitalogo"/></a></div>
      </div>
    </div>
</template>

<style scoped>
#solitalogo {
  width: 200px;
  height: auto;
  padding-bottom: 15px;
}

#sponsormessage {
  text-align: center;
  padding: 25px;
}
</style>