<script>
import HistogramThing from './HistogramThing.vue';

export default {
  name: "WeatherHistory",
  props: {
    indexByDay: {
      type: Boolean,
      default: false,
    },
    labelDate: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Object
    }
  },
  components: {
    HistogramThing
  }
}
</script>

<template>
  <ul class="nav nav-tabs" id="idTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="temperature-tab" data-bs-toggle="tab" data-bs-target="#temperature" type="button" role="tab" aria-controls="temperature" aria-selected="false">Keskilämpötila</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="rain-tab" data-bs-toggle="tab" data-bs-target="#rain" type="button" role="tab" aria-controls="rain" aria-selected="true">Sademäärä</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="ws-tab" data-bs-toggle="tab" data-bs-target="#ws" type="button" role="tab" aria-controls="ws" aria-selected="true">Tuulen nopeus</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="hum-tab" data-bs-toggle="tab" data-bs-target="#hum" type="button" role="tab" aria-controls="hum" aria-selected="true">Ilmankosteus</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="pres-tab" data-bs-toggle="tab" data-bs-target="#pres" type="button" role="tab" aria-controls="pres" aria-selected="true">Ilmanpaine</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="sr-tab" data-bs-toggle="tab" data-bs-target="#sr" type="button" role="tab" aria-controls="sr" aria-selected="true">Auringon säteilyteho</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="wr-tab" data-bs-toggle="tab" data-bs-target="#wr" type="button" role="tab" aria-controls="wr" aria-selected="true">WBGT</button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="temperature" role="tabpanel" aria-labelledby="temperature-tab">
        <HistogramThing :history="history" :height="100" historykey="ta60savg" description="Lämpötila (&deg;C)" :historyAvg="true" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" />
    </div>
    <div class="tab-pane fade" id="rain" role="tabpanel" aria-labelledby="rain-tab">
        <HistogramThing :history="history" :height="100" historykey="pr60ssum" description="Sademäärä (mm/m2)" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" />
    </div>
    <div class="tab-pane fade" id="ws" role="tabpanel" aria-labelledby="ws-tab">
        <HistogramThing :history="history" :height="100" historykey="ws2minavg" description="Tuulen nopeus (m/s)" :historyAvg="true" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" />
    </div>
    <div class="tab-pane fade" id="hum" role="tabpanel" aria-labelledby="hum-tab">
        <HistogramThing :history="history" :height="100" historykey="rh60savg" description="Ilmankosteus (%)" :historyAvg="true" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" :beginAtZero="false" />
    </div>
    <div class="tab-pane fade" id="pres" role="tabpanel" aria-labelledby="pres-tab">
        <HistogramThing :history="history" :height="100" historykey="qff60savg" description="Ilmanpaine (hPa)" :historyAvg="true" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" :beginAtZero="false" />
    </div>
    <div class="tab-pane fade" id="sr" role="tabpanel" aria-labelledby="sr-tab">
        <HistogramThing :history="history" :height="100" historykey="sr60ssum" description="Säteilyteho (W/m2)" :historyAvg="true" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" />
    </div>
    <div class="tab-pane fade" id="wr" role="tabpanel" aria-labelledby="wr-tab">
        <HistogramThing :history="history" :height="100" historykey="wbgt60sapx" description="Wet bulb global temperature (&deg;C)" :historyAvg="true" v-if="history" :labelDate="labelDate" :indexByDay="indexByDay" />
    </div>
  </div>

</template>

<style scoped>

</style>
