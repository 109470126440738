// import bootstrap after "yarn add bootstrap"
//  https://stackoverflow.com/questions/65547199/using-bootstrap-5-with-vue-3
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

import { createRouter, createWebHashHistory } from 'vue-router'
import FrontPage from "./pages/FrontPage.vue";
import CalendarPage from "./pages/CalendarPage.vue";
const routes = [
    { path: '/', component: FrontPage, meta: { title: 'Viimeisin mitattu säätila' } },
    { path: '/almanakka', component: CalendarPage, meta: { title: 'Säähistoria' } },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    linkActiveClass: 'active',
})
app.use(router)

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

var graphql_endpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT || document.location.protocol + "//" + document.location.host + "/query"
// HTTP connection to the API
const httpLink = createHttpLink({
    uri: graphql_endpoint,
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
})

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

app.use(apolloProvider)

var storedConcents = localStorage.getItem("CookieConcents")
if (storedConcents != null) {
    storedConcents = JSON.parse(storedConcents)
}

app.mount('#app')

// eof
