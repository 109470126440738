<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "HistogramThing",
  props: {
    indexByDay: {
      type: Boolean,
      default: false,
    },
    labelDate: {
      type: Boolean,
      default: false
    },
    beginAtZero: {
      type: Boolean,
      default: true
    },
    historyAvg: {
      type: Boolean,
      default: false
    },
    historykey: {
      type: String
    },
    description: {
      type: String
    },
    history: {
        type: Object,
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  components: { Bar },
  methods: {
    reduced(key) {
        var recordsByHour = {};
        var that=this;
        // reduce history data into totals for each hour
        var dataByHour = Array.from(this.history).reduce(function(dataByHour, datum){
            var date = new Date(Date.parse(datum.datetime))
            var indexMark = null
            if (!that.indexByDay) {
              indexMark = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).toISOString()
            } else {
              indexMark = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString()
            }
            var value = datum[key]
            dataByHour[indexMark] = (dataByHour[indexMark] || 0) + value;
            recordsByHour[indexMark] = (recordsByHour[indexMark] || 0) + 1;
            return dataByHour
        }, {})

        if (this.historyAvg) {
          // since we are averaging we need to divide the number based on the entries in the hour
          for (const [indexMark, value] of Object.entries(dataByHour)) {
            dataByHour[indexMark] = value / recordsByHour[indexMark];
          }
        }

        return dataByHour
    },
    label(inputDate) {
      var date = new Date(inputDate)
      if (this.labelDate) {
        if (!this.indexByDay) {
          return date.toLocaleString("fi-FI", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }); // get the string with hour and minute in it
        } else {
          return date.toLocaleString("fi-FI", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }); // get the string without hour:minute
        }
      } else {
        return date.getHours() // label with the hour
      }
    },
  },
  computed: {
    chartData: function() {
        var data = this.reduced(this.historykey)
        return {
            labels: Object.keys(data).map(value => {return this.label(value)}),
            datasets: [
                { label: this.description, backgroundColor: '#ADD8E6', data: Object.values(data)}
            ]
        }
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: this.beginAtZero
          }
        }
      }
    }
  }
}
</script>

<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
  />
</template>

<style scoped>

</style>
