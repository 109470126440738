<script>
import gql from 'graphql-tag'
import { defineAsyncComponent } from 'vue'

const TemperaturesCard = defineAsyncComponent(() =>
  import('../components/TemperaturesCard.vue')
)
const ConditionsCard = defineAsyncComponent(() =>
  import('../components/ConditionsCard.vue')
)
const SuninfoCard = defineAsyncComponent(() =>
  import('../components/SuninfoCard.vue')
)
const MooninfoCard = defineAsyncComponent(() =>
  import('../components/MooninfoCard.vue')
)
const CumulusCard = defineAsyncComponent(() =>
  import('../components/CumulusCard.vue')
)
const WeatherMap = defineAsyncComponent(() =>
  import('../components/WeatherMap.vue')
)
const LightningMap = defineAsyncComponent(() =>
  import('../components/LightningMap.vue')
)
const WebcamImage = defineAsyncComponent(() =>
  import('../components/WebcamImage.vue')
)
const WindCard = defineAsyncComponent(() =>
  import('../components/WindCard.vue')
)
const NewsCard = defineAsyncComponent(() =>
  import('../components/NewsCard.vue')
)
const WeatherHistory = defineAsyncComponent(() =>
  import('../components/WeatherHistory.vue')
)
const SponsorBox = defineAsyncComponent(() =>
  import('../components/SponsorBox.vue')
)

export default {
  name: "FrontPage",
  components: {
    TemperaturesCard,
    ConditionsCard,
    WindCard,
    SuninfoCard,
    MooninfoCard,
    CumulusCard,
    WeatherMap,
    WebcamImage,
    LightningMap,
    NewsCard,
    WeatherHistory,
    SponsorBox,
},
  mounted() {
    this.updateNews()
  },
  data() {
    return {
      news: [],
      site: {},
      log: {},
      history: null,
      history_load_hour: null,
      webcam: {},
      timerCount: 60,
      errors: {},
    };
  },
  computed: {
    errored: function() {
      if (Object.keys(this.errors).length > 0) {
        return true
      }
      return false
    },
    loading: function () {
      if (this.$apolloData.loading > 0) {
        return true
      }
      return false
    },
    webcam_error: function() {
      return "webcam" in this.errors
    },
    mawslog_error: function() {
      return "log" in this.errors
    },
    history_error: function() {
      return "history" in this.errors
    },
  },
  apollo: {
    history: {
      query: gql`query history_range($site_id: String!, $start_datetime: String!, $end_datetime: String!) {
        history: maws_log_range(site_id: $site_id, start_datetime: $start_datetime, end_datetime: $end_datetime) {
          datetime
          ta60savg
          pr60ssum
          ws2minavg
          rh60savg
          qff60savg
          sr60ssum
          wbgt60sapx
        }
      }`,
      variables() {
        var history_range = this.history_daterange();
        this.history_load_hour = new Date().getHours();
        return {
          site_id: process.env.VUE_APP_SITE,
          start_datetime: history_range[1].toISOString(),
          end_datetime: history_range[0].toISOString(),
        }
      },
      error(error, vm) {
        /*
        error(error, vm, key, type, options) is a hook called when there are errors. error is an Apollo error object with either a graphQLErrors property or a networkError property. vm is the related component instance. key is the reactive query key. type is either 'query' or 'subscription'. options is the final watchQuery options object.
        */
       vm.errors["history"] = true
       console.error(error)
      },
    },
    site: {
      query: gql`query site($id: ID!) {
        site(id: $id) {
          url
          description
          timezone
          coordinate {latitude, longitude}
        }
      }`,
      variables: {
        id: process.env.VUE_APP_SITE
      },
      error(error, vm) {
        /*
        error(error, vm, key, type, options) is a hook called when there are errors. error is an Apollo error object with either a graphQLErrors property or a networkError property. vm is the related component instance. key is the reactive query key. type is either 'query' or 'subscription'. options is the final watchQuery options object.
        */
       vm.errors["site"] = true
       console.error(error)
      },
    },
    webcam: {
      query: gql`query webcam_image_range($site_id: String!, $start_datetime: String!, $end_datetime: String!) {
        webcam: webcam_image_range(site_id: $site_id, start_datetime: $start_datetime, end_datetime: $end_datetime) {
          datetime
          url
          from_cache
        }
      }`,
      variables() {
        var range = this.webcam_daterange();
        return {
          site_id: process.env.VUE_APP_SITE,
          start_datetime: range[1].toISOString(),
          end_datetime: range[0].toISOString(),
        }
      },
      error(error, vm) {
        /*
        error(error, vm, key, type, options) is a hook called when there are errors. error is an Apollo error object with either a graphQLErrors property or a networkError property. vm is the related component instance. key is the reactive query key. type is either 'query' or 'subscription'. options is the final watchQuery options object.
        */
       vm.errors["webcam"] = true
       console.error(error)
      },
    },
    log: {
      query: gql`query maws_log($site_id: String!) {
        log: maws_log(site_id: $site_id) {
          datetime
          ta60savg
          dp60savg
          rh60savg
          pa60savg
          qff60savg
          sr60ssum
          pr60ssum
          wd2minavg
          ws2minavg
          wc60savg
          ah60savg
          ca60savg
          wbgt60sapx
        }
      }`,
      variables() {
        return {
          site_id: process.env.VUE_APP_SITE
        }
      },
      error(error, vm) {
        /*
        error(error, vm, key, type, options) is a hook called when there are errors. error is an Apollo error object with either a graphQLErrors property or a networkError property. vm is the related component instance. key is the reactive query key. type is either 'query' or 'subscription'. options is the final watchQuery options object.
        */
       vm.errors["log"] = true
       console.error(error)
      },
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value >= 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerCount = 60;
          // counter reached zero, do a refresh of data
          this.updateInformation();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async updateNews() {
      var cacheKey = +new Date();
      this.news = await fetch("/news.json?rnd=" + cacheKey)
        .then(response => { return response.json() })
        .catch(error => console.error(error));
    },
    updateInformation() {
      this.errors = {}

      this.$apollo.queries.log.refetch();

      var range = this.webcam_daterange();
      this.$apollo.queries.webcam.setVariables({
        site_id: process.env.VUE_APP_SITE,
        start_datetime: range[1].toISOString(),
        end_datetime: range[0].toISOString(),
      });

      if (this.history_load_hour != new Date().getHours()) {
        // only refresh history data every hour
        var history_range = this.history_daterange();
        this.$apollo.queries.history.setVariables({
          site_id: process.env.VUE_APP_SITE,
          start_datetime: history_range[1].toISOString(),
          end_datetime: history_range[0].toISOString(),
        })
        this.history_load_hour = new Date().getHours();
      }
    },
    history_daterange() {
      var now = new Date();
      var t1 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours()).getTime(); // current hour on the dot
      var t2 = t1 - 60 * 60 * 24 * 1000; // 24 hours ago
      t1 = new Date(t1);
      t2 = new Date(t2);
      return [t1, t2];
    },
    webcam_daterange() {
      var t1 = new Date().getTime(); // get seconds from epoch
      var t2 = t1 - 60 * 15 * 1000; // substract 15 minutes in seconds
      t1 = new Date(t1); // convert seconds since epoch to date object
      t2 = new Date(t2); // convert reduced seconds to date object
      return [t1,t2];
    },
    timestampToTimezoneFromUtc(timestamp, timezone) {
      return new Date(Date.parse(timestamp)).toLocaleString("fi-FI", {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
};
</script>

<template>

  <div class="container py-4">

    <div
      class="alert alert-danger d-flex align-items-center"
      role="alert"
      v-if="errored"
    >
      <div>
        <span class="bi-exclamation-triangle-fill" /> Tapahtui virhe: 
          <span v-if="webcam_error">Webcam ei ole käytettävissä.</span> <span v-if="mawslog_error">Viimeisin säätila ei saatavilla.</span> <span v-if="history_error">Säähistoria ei saatavilla.</span> Valitamme häiriötä.
      </div>
    </div>

    <div class="progress" style="height: 1px">
      <div class="progress-bar" :class="{ 'bg-warning': loading, 'bg-danger': errored }" role="progressbar" v-bind:style="{
        width: (100 * this.timerCount) / 60 + '%',
      }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
    </div>
    <div class="p-2 mb-2 bg-light rounded-3">
      <div class="container-fluid py-5 placeholder-glow">
        <h1 class="display-5 fw-bold">Viimeisin mitattu säätila</h1>

        <div :class="{ placeholder: !site.description }">
          <span class="bi-pin-map-fill" /> Mittauspaikka:
          <span v-if="site.description"><a :href="site.url" target="_new">{{ site.description }}</a></span>
        </div>
        <div :class="{ placeholder: !log.datetime }">
          <span class="bi-clock-history" /> Mittaushetki:
          <span v-if="log.datetime">{{
              timestampToTimezoneFromUtc(log.datetime, site.timezone)
          }}</span>
        </div>
        <br />
        <div class="row row-cols-1 row-cols-md-3 g-4">
          <TemperaturesCard :log="log" />
          <ConditionsCard :log="log" />
          <WindCard :log="log" />
          <CumulusCard :log="log" />
        </div>
      </div>
    </div>

    <SponsorBox />

    <div class="mb-2 row align-items-md-stretch">
      <div class="col-md-6">
        <div class="h-100 p-5 text-white bg-dark rounded-3" placeholder-glow>
          <h2>Webcam</h2>
          <WebcamImage :webcam="webcam" :site="site" v-if="!webcam_error" />
          <span class="bi-camera-video-off" v-if="webcam_error" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="h-100 p-5 bg-light border rounded-3">
          <h2>Sääkartat</h2>
          <WeatherMap />
        </div>
      </div>
    </div>
    <div class="mb-2 row align-items-md-stretch">
      <div class="col-md-6">
        <div class="h-100 p-5 bg-light border rounded-3" placeholder-glow>
          <h2>Salamapaikannin</h2>
          <LightningMap />
        </div>
      </div>
      <div class="col-md-6">
        <div class="h-100 p-5 bg-light border rounded-3" placeholder-glow>
          <NewsCard :news="news" />
        </div>
      </div>
    </div>

    <div class="p-2 mb-2 bg-light rounded-3">
      <div class="container-fluid py-5 placeholder-glow">
        <h1 class="display-5 fw-bold">Aurinko ja kuu</h1>
        <div class="row row-cols-1 row-cols-md-3 g-4">
          <SuninfoCard :log="log" :site="site" />
          <MooninfoCard :log="log" />
        </div>
      </div>
    </div>

    <div class="p-2 mb-2 bg-light rounded-3">
      <div class="container-fluid py-5 placeholder-glow">
        <h1 class="display-5 fw-bold">Säähistoria viimeiset 24 tuntia</h1>
        <WeatherHistory :history="history"/>
      </div>
    </div>

  </div>
</template>

<style scoped>
</style>
