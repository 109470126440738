<script>
import chroma from 'chroma-js'
import gql from 'graphql-tag'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import SponsorBox from '../components/SponsorBox.vue'
import WeatherHistory from '../components/WeatherHistory.vue'

export default {
  name: "CalendarPage",
  components: {
    SponsorBox,
    FullCalendar,
    WeatherHistory,
  },
  computed: {
    errored: function() {
      if (Object.keys(this.errors).length > 0) {
        return true
      }
      return false
    },
  },
  apollo: {
    history: {
      skip() {
        return !this.selectedRange.start; // do not execute if no selection is made
      },
      query: gql`query history_range($site_id: String!, $start_datetime: String!, $end_datetime: String!) {
        history: maws_log_range(site_id: $site_id, start_datetime: $start_datetime, end_datetime: $end_datetime) {
          datetime
		      ta60savg
          pr60ssum
          ws2minavg
          rh60savg
          qff60savg
          sr60ssum
          wbgt60sapx
        }
      }`,
      variables() {
        return {
          site_id: process.env.VUE_APP_SITE,
          start_datetime: this.selectedRange.start.toISOString(),
          end_datetime: this.selectedRange.end.toISOString(),
        }
      },
      error(error, vm) {
        /*
        error(error, vm, key, type, options) is a hook called when there are errors. error is an Apollo error object with either a graphQLErrors property or a networkError property. vm is the related component instance. key is the reactive query key. type is either 'query' or 'subscription'. options is the final watchQuery options object.
        */
       vm.errors["history"] = true
       console.error(error)
      },
    },
    aggregate: {
      skip() {
        return !this.seenRange.start; // do not execute if no range is set yet
      },
      query: gql`query aggregate_range($site_id: String!, $start_date: InputDate!, $end_date: InputDate!) {
        aggregate: day_aggregate_range(site_id: $site_id, start_date: $start_date, end_date: $end_date) {
          date
          tempavg
          s17
          rainsum
          sunradavg
          windavg
        }
      }`,
      variables() {
        var start_date = new Date(Date.parse(this.seenRange.start));
        var end_date = new Date(Date.parse(this.seenRange.end));
        return {
          site_id: process.env.VUE_APP_SITE,
          start_date: {year: start_date.getFullYear(), month: start_date.getMonth() + 1, day: start_date.getDate()},
          end_date: {year: end_date.getFullYear(), month: end_date.getMonth() + 1, day: end_date.getDate()},
        }
      },
      error(error, vm) {
        /*
        error(error, vm, key, type, options) is a hook called when there are errors. error is an Apollo error object with either a graphQLErrors property or a networkError property. vm is the related component instance. key is the reactive query key. type is either 'query' or 'subscription'. options is the final watchQuery options object.
        */
       vm.errors["aggregate"] = true
       console.error(error)
      },
    },
  },
  data() {
    return {
      showWindAvg: false,
      showSRSum: false,
      showTempAvg: true,
      showS17: false,
      showRainSum: true,
      colorMap: "temperature",
      aggregate: null,
      errors: {},
      history: null,
      selectedRange: {
          start: null,
          end: null,
          tooManyDays: false,
      },
      seenRange: {
        start: null,
        end: null,
      },
      calendarOptions: {
        eventOrder: 'title',
        eventColor: 'transparent',
        eventTextColor: '#000000',
        aspectRatio: 2.0,
        themeSystem: 'bootstrap5',
        unselectAuto: false,
        plugins: [ momentTimezonePlugin, dayGridPlugin, interactionPlugin, bootstrap5Plugin ],
        initialView: 'dayGridMonth',
        locale: 'fi',
        timeZone: 'Europe/Helsinki',
        firstDay: 1, // Sunday = 0, Monday = 1
        buttonText: {
            today: 'Tänään',
        },
        selectable: true,
        select: this.datesSelected,
        unselect: this.datesUnselected,
        datesSet: this.handleMonthChange,
        validRange: function(nowDate) {
          return {
            end: nowDate,
          };
        },
      }
    }
  },
  methods: {
    handleMonthChange: function(payload) {
      this.seenRange.start = payload.startStr;
      this.seenRange.end = payload.endStr;
    },
    datesUnselected: function() {
      this.selectedRange.start = null
      this.selectedRange.end = null
      this.history = null
    },
    datesSelected: function(selectInfo) {
      this.selectedRange.start = selectInfo.start
      this.selectedRange.end = selectInfo.end
      this.selectedRange.tooManyDays = this.tooManyDays(selectInfo.start, selectInfo.end)
    },
    tooManyDays: function(date1, date2) {
      var diffInSecs = date2.getTime() - date1.getTime()
      var diffInDays = diffInSecs = diffInSecs / (1000 * 3600 * 24)
      if (diffInDays <= 7) {
        return false
      }
      return true
    },
    updateCalendarEvents: function() {      
      // the month view changed and we succesfully loaded the aggregate for each day from the server. now we need to change those into an array
      //  that we will provide for the calendar to display
      var events = []
      this.aggregate.forEach((aggr) => {
        if (this.colorMap == "temperature") {
          // cells background based on the temperature color gradient
          events.push({
            start: aggr.date,
            display: 'background',
            backgroundColor: this.getHeatMapColor(aggr.tempavg),
          })
        } else if (this.colorMap == "rain") {
          // cells background based on rain sum
          events.push({
            start: aggr.date,
            display: 'background',
            backgroundColor: this.getRainMapColor(aggr.rainsum),
          })
        } else if (this.colorMap == "sunrad") {
          // cells background based on sun radiation sum
          events.push({
            start: aggr.date,
            display: 'background',
            backgroundColor: this.getSRMapColor(aggr.sunradavg),
          })
        } else if (this.colorMap == "wind") {
          // cells background based on wind speed avg
          events.push({
            start: aggr.date,
            display: 'background',
            backgroundColor: this.getWSMapColor(aggr.windavg),
          })
        }

        // avg temperature as an event for the day
        if (this.showTempAvg) {
          events.push({
            title: "Lämpötila: " + aggr.tempavg + " °C",
            start: aggr.date,
          })
        }

        // s17 number as event for the day
        if (this.showS17) {
          events.push({
            title: "S17: " + aggr.s17 + " °Cvrk",
            start: aggr.date,
          })
        }

        // sun radiation sum as event for the day
        if (this.showSRSum) {
          events.push({
            title: "Teho: " + aggr.sunradavg + " W/m2",
            start: aggr.date,
          })
        }

        // rain amount
        if (aggr.rainsum > 0 && this.showRainSum) {
            events.push({
            title: "Sade: " + aggr.rainsum + " mm/m2",
            start: aggr.date,
          })
        }

        // average wind speed
        if (this.showWindAvg) {
            events.push({
            title: "Tuuli: " + aggr.windavg + " m/s",
            start: aggr.date,
          })
        }

      })
      // update events
      this.calendarOptions.events = events;
    },
    getHeatMapColor(temperature) {
      var xMax = 1, xMin = 0, yMax = 25, yMin = -20;
      var color_scale = chroma.scale(['Violet', 'Blue', 'Yellow', 'Red']);

      var percent = (temperature - yMin) / (yMax - yMin);
      var outputX = percent * (xMax - xMin) + xMin;

      return color_scale(outputX).hex()
    },
    getRainMapColor(rainsum) {
      var xMax = 1, xMin = 0, yMax = 10, yMin = 0;
      var color_scale = chroma.scale(['White', 'Blue']);

      var percent = (rainsum - yMin) / (yMax - yMin);
      var outputX = percent * (xMax - xMin) + xMin;

      return color_scale(outputX).hex()
    },
    getSRMapColor(value) {
      var xMax = 1, xMin = 0, yMax = 300, yMin = 0;
      var color_scale = chroma.scale(['White', 'Magenta']);

      var percent = (value - yMin) / (yMax - yMin);
      var outputX = percent * (xMax - xMin) + xMin;

      return color_scale(outputX).hex()
    },
    getWSMapColor(value) {
      var xMax = 1, xMin = 0, yMax = 5, yMin = 0;
      var color_scale = chroma.scale(['White', 'Red']);

      var percent = (value - yMin) / (yMax - yMin);
      var outputX = percent * (xMax - xMin) + xMin;

      return color_scale(outputX).hex()
    },
  },
  mounted() {
    let calendarApi = this.$refs.fullCalendar.getApi()
    calendarApi.select(new Date().toISOString().split('T')[0]) // initially select today when starting
  },
  watch: {
    showTempAvg: {
      handler() {
        // show/hide temp average clicked
        this.updateCalendarEvents()
      }
    },
    showSRSum: {
      handler() {
        // show/hide sun radiation clicked
        this.updateCalendarEvents()
      }
    },
    showS17: {
      handler() {
        // show/hide S17 clicked
        this.updateCalendarEvents()
      }
    },
    showRainSum: {
      handler() {
        // show/hide rain sum clicked
        this.updateCalendarEvents()
      }
    },
    showWindAvg: {
      handler() {
        // show/hide wind speed from day cell
        this.updateCalendarEvents()
      }
    },
    colorMap: {
      handler() {
        // enable/disable heatmap and thus update the calendar events
        this.updateCalendarEvents()
      }
    },
    aggregate: {
      handler() {
        // the aggregate was (re)created and needs to be updated
        this.updateCalendarEvents()
      }
    }
  }
}
</script>

<template>
  <div class="container py-4">

    <div
      class="alert alert-danger d-flex align-items-center"
      role="alert"
      v-if="errored"
    >
      <div>
        <span class="bi-exclamation-triangle-fill" /> Tapahtui virhe, yritä hetken kuluttua uudelleen. Valitamme häiriötä.
      </div>
    </div>

      <div class="p-2 mb-2 bg-light rounded-3">
        <div class="container-fluid py-3">
          <h1 class="display-5 fw-bold">Säähistoria</h1>
          <div class="container-fluid py-3">

            <a data-bs-toggle="collapse" href="#collapseSettings" role="button" aria-expanded="false" aria-controls="collapseSettings">
              Kalenterin asetukset <span class="bi-chevron-down" />
            </a>

            <div class="collapse" id="collapseSettings">
              <div class="card card-body">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <b>Päiväsolun väri</b>
                      <div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="colorMapRadio" id="colorMapRadio0" v-model="colorMap" value="off">
                          <label class="form-check-label" for="colorMapRadio0">
                            Värikartta: pois päältä
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="colorMapRadio" id="colorMapRadio1" v-model="colorMap" value="temperature">
                          <label class="form-check-label" for="colorMapRadio1">
                            Värikartta: lämpötila
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="colorMapRadio" id="colorMapRadio2" v-model="colorMap" value="rain">
                          <label class="form-check-label" for="colorMapRadio2">
                            Värikartta: sademäärä
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="colorMapRadio" id="colorMapRadio3" v-model="colorMap" value="sunrad">
                          <label class="form-check-label" for="colorMapRadio3">
                            Värikartta: auringon säteilyteho
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="colorMapRadio" id="colorMapRadio4" v-model="colorMap" value="wind">
                          <label class="form-check-label" for="colorMapRadio4">
                            Värikartta: tuulen nopeus
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <b>Näytettävät tiedot</b>
                      <div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkTemperature" v-model="showTempAvg">
                          <label class="form-check-label" for="checkTemperature">
                            Vuorokauden keskilämpötila
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkS17" v-model="showS17">
                          <label class="form-check-label" for="checkS17">
                            Vuorokauden lämpötarveluku (S17)
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkRain" v-model="showRainSum">
                          <label class="form-check-label" for="checkRain">
                            Vuorokauden sadesumma
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkSunRad" v-model="showSRSum">
                          <label class="form-check-label" for="checkSunRad">
                            Vuorokauden auring. säteilyteho
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkWindAvg" v-model="showWindAvg">
                          <label class="form-check-label" for="checkWindAvg">
                            Vuorokauden tuulen nop. keskiarvo
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <span v-if="$apollo.queries.aggregate.loading" class="spinner-border text-primary" role="status"><span class="visually-hidden">Ladataan historiaa...</span></span>
          <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
        </div>
        <div class="container-fluid py-3 placeholder-glow">
          <span v-if="$apollo.queries.history.loading" class="spinner-border text-info" role="status"><span class="visually-hidden">Ladataan historiaa...</span></span>
          <WeatherHistory v-if="history" :history="history" :labelDate="true" :indexByDay="selectedRange.tooManyDays" />
          <div v-if="!history && !$apollo.loading">Valitse päivä tai ajanjakso kalenterista nähdäksesi tilastot.</div>
        </div>
      </div>
      <SponsorBox />
  </div>
</template>

<style scoped>
</style>