<script>

export default {
    name: "App",
    watch: {
        $route: {
            immediate: false,
            handler(to) {
                document.title = "Sää Hämeenlinnassa - " + (to.meta.title || "");
            },
        },
    },
    data() {
        return {
            "ConcentTo": {
                "FunctionalCookies": true,
            },
            "ConcentOffcanvasVisible": false,
        };
    },
    mounted() {
        this.showConcentRequest();
    },
    methods: {
        showConcentRequest(force = false) {
            var storedConcents = localStorage.getItem("CookieConcents");
            if (storedConcents != null) {
                this.ConcentTo = JSON.parse(storedConcents);
            }
            if (storedConcents == null || force == true) {
                // display the concent request canvas
                this.ConcentOffcanvasVisible = true;
            }
        },
        handleConcent() {
            localStorage.setItem("CookieConcents", JSON.stringify(this.ConcentTo));
            this.ConcentOffcanvasVisible = false;
            location.reload();
        },
    },
};
</script>

<template>

<div class="container py-4">
    <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">Sää Hämeenlinnassa</a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <router-link class="nav-link" to="/">Säätila nyt</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/almanakka">Säähistoria</router-link>
            </li>
        </ul>
        </div>
    </div>
    </nav>
</div>

  <router-view />

  <div class="container py-4">
    <footer class="pt-3 mt-4 text-muted border-top">
      &copy; 2022- Sää Hämeenlinnassa ja sen <a href="mailto:saa-hameenlinnassa@virpi.net">tekijät</a><br/>
      Hallinnoi <button type="button" class="btn-sm btn-light" @click="showConcentRequest(true)">evästeitä</button>.
    </footer>
  </div>

  <div v-show="ConcentOffcanvasVisible" class="offcanvas offcanvas-bottom show" tabindex="-1" id="offcanvasCookieConcent"
    data-bs-backdrop="false" data-bs-scroll="true">
      <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasBottomLabel">Evästeiden hallinta</h5>
      </div>
      <div class="offcanvas-body small">
          <div class="row align-items-center">
              <div class="col">

                  <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" v-model="ConcentTo.FunctionalCookies" id="cookieFunctional" disabled>
                  <label class="form-check-label" for="cookieFunctional">
                      Toiminnalliset evästeet
                  </label>
                  </div>

              </div>
              
              <div class="col">
                  <button type="button" class="btn btn-primary btn-sm" @click="handleConcent">Hyväksy valinnat</button>
              </div>
          </div>
      </div>
  </div>

</template>

<style>
</style>